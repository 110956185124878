<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova linha</span>
    </button>
    <b-table
      :data="allBoardingLines"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes`)"
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="id"
        label="ID"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.id }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{ props.row.id }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="identification"
        label="Número"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.identification }}</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Número:</strong>
                <small>{{ props.row.identification }}</small>
                <br />
                <strong>Pontos:</strong>
                <span
                  v-for="(item, index) in props.row.boarding_points"
                  :key="index"
                >
                  <br />
                  <small
                    >- {{ item.boarding_place }} (R$
                    {{ item.pivot.value.toFixed(2).replace('.', ',') }})</small
                  >
                </span>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Número *">
              <b-input v-model="model.identification" required></b-input>
            </b-field>
            <b-field label="Pontos">
              <button
                class="button field is-info is-light"
                @click="addBoardingPoint"
              >
                <b-icon icon="plus"></b-icon>
                <span>Adicionar ponto</span>
              </button>
            </b-field>
            <b-field>
              <b-collapse
                class="card"
                animation="slide"
                v-for="(item, index) of boardingPoints"
                :key="index"
                :open="isOpen == index"
                @open="isOpen = index"
              >
                <div
                  slot="trigger"
                  slot-scope="props"
                  class="card-header"
                  role="button"
                >
                  <p class="card-header-title">
                    {{
                      boardingPoints[index].id
                        ? getBoardingPointByID(
                            boardingPoints[index].id
                          ).toUpperCase()
                        : 'Nenhum ponto selecionado'
                    }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <b-field label="Ponto">
                      <b-select expanded v-model="boardingPoints[index].id">
                        <option
                          v-for="(point, index) in allBoardingPoints"
                          :key="index"
                          :value="point.id"
                        >
                          ID: {{ point.id }} - LOCAL: {{ point.boarding_place }}
                        </option>
                      </b-select>
                    </b-field>
                    <b-field label="Valor *">
                      <b-input
                        required
                        v-money="moneyFormat"
                        v-model="boardingPoints[index].value"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
                <footer class="card-footer">
                  <a
                    @click="removeBoardingPoint(index)"
                    class="card-footer-item"
                    >Remover</a
                  >
                </footer>
              </b-collapse>
            </b-field>
            <b-field> </b-field>
            <b-button type="button field is-info" class="mt-10" @click="save"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/boarding-lines/store/service'
import BoardingLine from '../models/boarding-line'
import StatesCities from '../assets/data/estados-cidades.json'

export default {
  name: 'Taxes',
  data() {
    return {
      model: BoardingLine.model,
      isModalVisible: false,
      showDetailIcon: false,
      isCardModalActive: false,
      cities: [],
      states: [],
      boardingPoints: [],
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('boardingPoints', ['allBoardingPoints']),
    ...mapState('boardingLines', ['allBoardingLines']),
  },
  methods: {
    ...mapActions('boardingPoints', ['getAllBoardingPoints']),
    ...mapActions('boardingLines', ['getAllBoardingLines']),
    async init() {
      await this.getAllBoardingPoints()
      await this.getAllBoardingLines()

      console.log('LINE ', this.allBoardingLines)
      this.states = StatesCities.states.map((x) => {
        return { id: x.initials, name: x.name }
      })

      this.cities = StatesCities.states[0].cities
      this.model.city = StatesCities.states[0].cities[0]
      this.model.state = this.states[0].id
    },
    getBoardingPointByID(id) {
      return this.allBoardingPoints.find((x) => x.id === id).boarding_place
    },
    statesCities() {
      this.cities = StatesCities.states.find(
        (x) => x.initials === this.model.state
      ).cities
    },
    async save() {
      const boardingLine = { ...this.model }

      const boardingPoints = this.boardingPoints.map((x) => {
        return { id: x.id, value: x.value.replace('R$ ', '') }
      })

      boardingLine.boarding_points = boardingPoints

      console.log('POINTS ', boardingLine, boardingPoints)

      try {
        const save = boardingLine.id
          ? await service.updateBoardingLine(boardingLine)
          : await service.saveBoardingLine(boardingLine)

        await this.$success('Linha')
        await location.reload(true)
      } catch (error) {
        console.log('error', error)
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover a linha?')

        if (result.isConfirmed) {
          await service.deleteBoardingLine(id)

          await this.$delete('Linha')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    onEdit(item) {
      this.model = { ...item }
      setTimeout(() => {
        this.boardingPoints = item.boarding_points.map((x) => {
          return { id: x.id, value: parseFloat(x.pivot.value).toFixed(2) }
        })
      }, 600)
    },
    addBoardingPoint() {
      this.boardingPoints.push({
        id: null,
        value: 0,
      })
    },
    removeBoardingPoint(index) {
      this.boardingPoints.splice(index, 1)
    },
  },
}
</script>

<style scoped></style>
